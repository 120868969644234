.GalleryWrapper {
  margin-top: 4rem;
  .galleryItem {
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: 18rem;
      object-fit: cover;
    }
  }
}

.whatWrapper {
  margin-top: 5rem;
  .whatCard {
    box-shadow: 1px 2px 6px rgb(220, 220, 220);
    padding: 1.5rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    margin-top: 2rem;

    &-icon {
      border: $border;
      padding: 0.1rem 1rem;
      display: inline-block;
      margin-bottom: 1.5rem;
      font-size: 3rem;
      color: color(primary, base);
      border-color: color(primary, base);
      border-radius: 6px;
    }

    &-title {
      margin-bottom: 1rem;
    }
    &-desc {
      color: color(text-light);
      height: 7rem;
    }
  }
}
