.absnavbar {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 99999999999;
  backdrop-filter: blur(2.5px);
  -webkit-backdrop-filter: blur(1.5px);
  background: #254e9a;
}

.navWrapper {
  @include flex-center;
  justify-content: space-between;
  padding: 0.3rem 0;

  &__navBrand {
    margin-bottom: 0;
    position: relative;

    img {
      width: 6rem;
      z-index: 2;
      border-radius: 4px;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.345);
      position: absolute;
      top: -25px;
      @include responsive-for(md) {
        width: 4rem;
      }
    }
  }
  &__navItems {
    // padding: 0;
    margin: 0;
    @include flex-center;
    justify-content: space-between;

    &--items {
      padding: 1.5rem 1.5rem;
      cursor: pointer;
      font-weight: 200;
    }
    @media screen and (max-width: 1040px) {
      display: none;
    }
  }
  &__mdNav {
    display: none;
    z-index: 9999 !important;

    @media all and (max-width: 1040px) {
      display: block;

      .open_mdNav {
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
        font-size: 1.8rem;
      }
      .mdNav_list_container {
        position: absolute;
        top: 0;
        right: -100%;
        height: 100vh;
        min-height: 600px;
        width: 100%;
        z-index: 9 !important;
        background-color: rgba(0, 0, 0, 0.719);
        transition: all 300ms linear;
        display: flex;
        justify-content: flex-end;
        .mdNav_list {
          background-color: white;
          width: 25%;
          min-width: 25rem;
          height: 100%;
          padding: 1rem 0.5rem;
          li {
            &:first-child {
              display: flex;
              justify-content: space-between;

              .close_mdNav_container {
                padding-top: 0.8rem;
                .close_mdNav {
                  border: none;
                  background-color: transparent;
                  outline: none;
                  font-size: 1.3rem;
                }
              }
            }
            &:not(:first-child) {
              margin-left: 0.5rem;
            }

            .mdnavBrand {
              img {
                width: 5rem;
                margin-left: 1.5rem;
              }
            }
          }
          @include responsive-for(sm) {
            width: 100%;
            min-width: 100%;
          }
        }
      }
      .showmdNav {
        right: 0;
      }
    }
  }
}
@media all and (max-width: 1040px) {
  #dropdown-basic {
    padding: 0 !important;
  }
  .dropdown-menu {
    margin-top: 1rem !important;
  }
}
.activeSidebar {
  color: color(secondary, base);
  &:hover {
    color: color(secondary, base) !important;
  }
}

.notActive {
  color: rgb(255, 255, 255);
  &:hover {
    color: color(secondary, base) !important;
  }
}
.activeSidebarMob {
  color: color(secondary, base);
  &:hover {
    color: color(primary, base) !important;
  }
}

.notActiveMob {
  color: color(primary, base) !important;
  &:hover {
    color: color(primary, base) !important;
  }
}

section[role='navDrop'] {
  .btn-success {
    color: color(text-light);
    background-color: #ffffff;
    border: none;
  }

  .btn-check:active + .btn-success,
  .btn-check:checked + .btn-success,
  .btn-success.active,
  .btn-success:active,
  .show > .btn-success.dropdown-toggle {
    color: color(text-light);
    background-color: #ffffff;
    border: none;
  }
}
